import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { HistoricalDriverContributionComponent } from './historical-driver-contribution.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { AgGridModule } from '@ag-grid-community/angular';
import { GridDatePickerHeaderModule } from '../../grid-datepicker-header/grid-date-picker-header.module';
import { EdfxPipesModule } from '../../../pipes/edfx-pipes.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import '../../../../vendors';
import { TranslocoRootModule } from '../../../transloco/transloco-root.module';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';

ModuleRegistry.registerModules([ClientSideRowModelModule, ServerSideRowModelModule]);

@NgModule({
  declarations: [HistoricalDriverContributionComponent],
  imports: [CommonModule,
    TranslocoModule,
    TranslocoLocaleModule,
    TranslocoRootModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    MatLegacyTabsModule,
    AgGridModule,
    CommonModule,
    EdfxPipesModule,
    FlexLayoutModule,
    GridDatePickerHeaderModule
  ],
  exports: [HistoricalDriverContributionComponent]
})
export class HistoricalDriverContributionModule { }
