export const DEFAULT_TEXT_NA = 'N/A';
export const FREQUENCY_MONTHLY = 'monthly';

export const INSOLVENT_BANKRUPT = ['act_imp', 'bkrpt'];

export const HTTP_CORRELATION_ID = 'x-correlation-id';
export const HTTP_SESSION_ID = 'x-session-id';
export const HTTP_HEADERS_AUTHORIZATION = 'Authorization';

export const ENGLISH_LANGUAGE = 'en-US';

export const MOMENT_FORMATS = {
    payload: 'YYYY-MM-DD',
    parse: {
      dateInput: 'MM/YYYY'
    },
    display: {
      dateInput: 'MM/DD/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
      dayShortMonthYearLabel: 'MMM D, YYYY',
      dayMonthYearLabel: 'MMMM D, YYYY',
      fulldayMonthYearLabel: 'MMMM DD, YYYY',
      fulldayMonthYearWithTimeLabel: 'MMMM DD, YYYY [at] hh:mm A',
      fullYearDateMonthInDash: 'yyyy-MM-dd'
    }
  };
