import { ColDef, GridOptions, GridReadyEvent, ICellRendererParams } from '@ag-grid-community/core';
import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Entitlements } from 'interfaces/constants';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { IEntitlement } from '../../../interfaces/entitlement.interface';
import { IHistoricalDriverContributionCustomData } from '../../../interfaces/historical-driver-contribution-data.interface';
import { ITabbedGrid } from '../../../interfaces/tabbed-grid.interface';
import { OrdinalFormatterPipe } from '../../../pipes/ordinal-formatter.pipe';
import { ApiService } from '../../../services/api.service';
import { MomentService } from '../../../services/moment.service';
import { ChartUtils } from '../../../utils/charts.util';
import { DEFAULT_TEXT_NA, ENGLISH_LANGUAGE, FREQUENCY_MONTHLY, INSOLVENT_BANKRUPT, MOMENT_FORMATS } from '../../../utils/constants';
import { DateUtils } from '../../../utils/date.utils';
import { DriversUtils } from '../../../utils/drivers.utils';
import { Utils } from '../../../utils/utils';
import { GridDatePickerHeaderComponent } from '../../grid-datepicker-header/grid-date-picker-header.component';
import {
  DriverTypes,
  ICompanyModel,
  ICompanyProfile,
  IEntitySearchResponse,
  IPDHistoricalInputPayload,
  IPdDriver,
  IPdDriverDetails,
  IPdDriverInput,
  IPdDriverWithColor,
  IPdDriversChartData,
  ModelId
} from '../edfx-historical-driver-contribution/../../../interfaces/company-data.interface';
import { IMmsModel, IPdModelCategoryDriver } from '../edfx-historical-driver-contribution/../../../interfaces/mms-model.interface';

@Component({
  selector: 'edfx-historical-driver-contribution',
  templateUrl: './historical-driver-contribution.component.html',
  styleUrls: ['./historical-driver-contribution.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class HistoricalDriverContributionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public inactiveMessage = this.translocoService.translate('CHART.OVERWRITE_MESSAGE');
  @Input() public showVerticalTabs = false;
  @Input() public titleIcon: string | null = null;

  public companyModelName: string;
  public companyName: string;
  public loading: boolean;
  public isFinancialModel = true;
  public seriesData: IPdDriverWithColor[];
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public pdDriversChartData: IPdDriversChartData;
  public historicalDriverInputs: IPDHistoricalInputPayload;
  public companyModel: ICompanyModel;
  public mmsModel: IMmsModel;
  private primaryModelId = '';
  public tabbedGrids: ITabbedGrid[] = [];
  tabNames: any[] = [];
  title = '';
  public hasMmsModel: boolean;
  public hasRatiosAndPercentiles: boolean;
  public heatchartRatiosAndPercentiles: any;

  public seriesDataLoading: boolean;
  public pdDriversDataLoading: boolean;
  public heatChartDataLoading: boolean;
  public displayInactiveMessage = false;
  public gridOptions: GridOptions[];
  todaysDate = formatDate(new Date(), MOMENT_FORMATS.display.fullYearDateMonthInDash, ENGLISH_LANGUAGE);
  tokenInvalid: boolean;
  requestInProgress: boolean;
  mmsModel$?: Observable<IMmsModel>;
  isLoading = false;
  historicalDriversUnavailable = false;
  hasEntitlement = false;
  entityId: string;
  invalidEntityId = false;
  historicalDriverContributionCustomData: IHistoricalDriverContributionCustomData;

  @Input() token!: string;
  @Input() inputData: string | IHistoricalDriverContributionCustomData;

  constructor(
    private translocoService: TranslocoService,
    private ordinalFormatterPipe: OrdinalFormatterPipe,
    private apiService: ApiService,
    private ref: ChangeDetectorRef
  ) {
    this.seriesDataLoading = true;
    this.pdDriversDataLoading = true;
    this.heatChartDataLoading = true;
    // Uncomment for development
    /* this.historicalDriverContributionCustomData = {
      entityId: 'US912197729',
      asOfDate: ''
    };
    this.token = // eslint-disable-next-line
    '';
    this.inputData = JSON.stringify(this.historicalDriverContributionCustomData);
    */

  }

  ngOnInit(): void {
    if (!Utils.isNullOrUndefined(this.inputData) && !Utils.isNullOrUndefined(this.token)) {
      this.getHistoricalDriverContributionCustomDataFromInput();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let shouldGetHistoricalDriverContributionInfo = false;

    if (
      changes['inputData'] &&
      !Utils.isNullOrUndefined(changes['inputData'].currentValue)
    ) {
      this.inputData = changes['inputData'].currentValue;
      shouldGetHistoricalDriverContributionInfo = true;
    }

    if (changes['token'] && !Utils.isNullOrUndefined(changes['token'].currentValue)) {
      this.token = changes['token'].currentValue;
      shouldGetHistoricalDriverContributionInfo = true;
    }

    if (shouldGetHistoricalDriverContributionInfo) {
      this.getHistoricalDriverContributionCustomDataFromInput();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public hasTabbedGridData() {
    return this.tabbedGrids.length > 0;
  }

  public hasData() {
    if (Utils.isNullOrUndefined(this.pdDriversChartData)) {
      return false;
    }
    const contributionsHasData = Utils.hasItems(this.pdDriversChartData.contributions);
    const pdsHasData = Utils.hasItems(this.pdDriversChartData.pds);
    const ratiosHasData = Utils.hasItems(this.pdDriversChartData.ratios);
    const percentilesHasData = Utils.hasItems(this.pdDriversChartData.percentiles);
    return contributionsHasData && pdsHasData && ratiosHasData && percentilesHasData;
  }

  getHistoricalDriverContributionCustomDataFromInput(){
    if(typeof this.inputData === 'string'){
      this.historicalDriverContributionCustomData =
      JSON.parse(this.inputData) as IHistoricalDriverContributionCustomData;
    }
    else if (typeof this.inputData === 'object'){
      this.historicalDriverContributionCustomData = this.inputData;
    }
    this.initializeComponent();
  }

  initializeComponent() {
    const entitlement_list = [
      Entitlements.MIR_MEDIAN_CREDIT_SPREAD,
      Entitlements.ORBIS_ALL_COMPANIES,
      Entitlements.PRIVATE_ENTITLEMENT,
      Entitlements.PUBLIC_ENTITLEMENT,
      Entitlements.PUBLIC_PLUS_ENTITLEMENT
    ];
    this.isLoading = true;

    if (!Utils.isNullOrUndefined(this.token)) {
      this.apiService.setToken(this.token);
    }

    if (!this.historicalDriverContributionCustomData?.entityId) {
      return;
    }
    this.tokenInvalid = false;
    this.requestInProgress = true;
    this.apiService
      .getEntitlements(entitlement_list)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
            this.isLoading = false;
          }
          this.requestInProgress = false;
          this.hasEntitlement = true;
          return of();
        })
      )
      .subscribe(entitlements => this.onEntitlementsReceived(entitlements));
  }

  onEntitlementsReceived(entitlements: IEntitlement[]) {
    this.hasEntitlement = entitlements?.some(
      entitlement => entitlement.name === Entitlements.PRIVATE_ENTITLEMENT || entitlement.name === Entitlements.PUBLIC_ENTITLEMENT
    );
    if (!this.hasEntitlement) {
      this.requestInProgress = false;
      return;
    }

    this.entityId = this.historicalDriverContributionCustomData?.entityId;

    this.getEntityId();
  }

  getEntityId(): void {
    const currentEntityId = this.entityId;
    this.apiService
      .getEntityId(currentEntityId, false)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
          }
          this.requestInProgress = false;
          return of();
        })
      )
      .subscribe((entityIdResult: IEntitySearchResponse) => {
        if (Utils.isNullOrUndefined(entityIdResult)) {
          this.invalidEntityId = true;
          this.isLoading = false;
          return;
        }
        if (entityIdResult.entities.length === 0) {
          this.getCustomEntityId(currentEntityId);
          return;
        }

        this.entityId = entityIdResult?.entities?.length > 0 ? entityIdResult.entities[0]?.entityId : this.entityId;
        this.onEntityIdReceived();
      });
  }

  getCustomEntityId(currentEntityId: string) {
    this.apiService
      .getEntityId(currentEntityId, true)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
          }
          this.requestInProgress = false;
          return of();
        })
      )
      .subscribe((entityIdResult: IEntitySearchResponse) => {
        if (Utils.isNullOrUndefined(entityIdResult)) {
          this.invalidEntityId = true;
          this.isLoading = false;
          return;
        }

        if (entityIdResult.entities.length === 0) {
          this.invalidEntityId = true;
          this.isLoading = false;
          return;
        }
        this.entityId = entityIdResult?.entities?.length > 0 ? entityIdResult.entities[0]?.entityId : this.entityId;
        this.onEntityIdReceived();
      });
  }

  onEntityIdReceived() {
    const analysisDate = !!this.historicalDriverContributionCustomData?.asOfDate
      ? this.historicalDriverContributionCustomData?.asOfDate
      : this.todaysDate;

    this.translocoService
      .selectTranslate('COMPANY.DRIVERS.HISTORICAL_DRIVER_CONTRIBUTION_GRID.TAB_NAMES.VALUE')
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => (this.tabNames[0] = value));
    this.translocoService
      .selectTranslate('COMPANY.DRIVERS.HISTORICAL_DRIVER_CONTRIBUTION_GRID.TAB_NAMES.CONTRIBUTION')
      .pipe(takeUntil(this.destroy$))
      .subscribe(contribution => (this.tabNames[1] = contribution));
    this.translocoService
      .selectTranslate('COMPANY.DRIVERS.HISTORICAL_DRIVER_CONTRIBUTION_GRID.TAB_NAMES.PERCENTILE')
      .pipe(takeUntil(this.destroy$))
      .subscribe(percentile => (this.tabNames[2] = percentile));
    this.translocoService
      .selectTranslate('COMPANY.DRIVERS.HISTORICAL_DRIVER_CONTRIBUTION_GRID.TITLE')
      .pipe(takeUntil(this.destroy$))
      .subscribe(title => (this.title = title));
    this.tokenInvalid = false;
    this.requestInProgress = true;

    combineLatest([this.apiService.getModel(this.entityId, analysisDate), this.apiService.getCompanyProfile(this.entityId)])
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
            this.isLoading = false;
          }
          this.requestInProgress = false;
          return of();
        })
      )
      .subscribe(([model, profile]) => {
        this.onEntityInfoRecieved(model, profile);
      });
  }

  private onEntityInfoRecieved(model: ICompanyModel, profile: ICompanyProfile): void {
    if (!model || !profile) {
      return;
    }
    this.companyModel = model;
    this.companyName = profile.name || '';
    this.tokenInvalid = false;
    this.requestInProgress = false;

    const modelId = this.companyModel.modelId;
    this.primaryModelId = modelId ? modelId.split('+')[0] : '';
    const isBenchmarkModel = this.primaryModelId.toLowerCase().includes(ModelId.BENCHMARK);
    const isPaymentModel = this.primaryModelId.toLowerCase().includes(ModelId.PAYMENT);
    this.isFinancialModel = !isBenchmarkModel && !isPaymentModel;
    const numberOfYears = this.isFinancialModel ? 5 : 4;

    const latestKnownPdDate = DateUtils.getLatestDate(this.companyModel.latestKnownPdDate);
    const inputEndDate = latestKnownPdDate.format(MOMENT_FORMATS.payload);
    const inputStartDate = latestKnownPdDate.subtract(numberOfYears, 'y').format(MOMENT_FORMATS.payload);

    this.historicalDriverInputs = {
      entityId: this.companyModel.id,
      endDate: inputEndDate,
      startDate: inputStartDate,
      frequency: FREQUENCY_MONTHLY,
      fso: true
    };

    const isPublicModel = this.primaryModelId.toLowerCase().includes(ModelId.EDF9);
    this.historicalDriversUnavailable = isBenchmarkModel || isPublicModel;

    this.displayInactiveMessage = ChartUtils.displayInactiveMessage(model.confidenceIndicator, INSOLVENT_BANKRUPT, isPublicModel);

    if (!this.historicalDriversUnavailable) {
      this.subscribeToMonthlyPDHistoricalDataAndMMSData();
    } else {
      this.isLoading = false;
    }
  }

  private subscribeToMonthlyPDHistoricalDataAndMMSData() {
    this.mmsModel$ = this.apiService.getMmsModel(this.primaryModelId, true, true, true);
    combineLatest([this.apiService.getMonthlyHistoricalPdDrivers(this.historicalDriverInputs), this.mmsModel$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([historicalPdDrivers, mmsModel]) => {
        if (!historicalPdDrivers || !mmsModel) {
          return;
        }
        this.processMmsModel(mmsModel);

        if (this.hasPdDriversData(historicalPdDrivers)) {
          this.tabbedGrids = this.createDriversGrids(historicalPdDrivers);
          this.ref.detectChanges();
        }
        this.isLoading = false;
      });
  }

  private processMmsModel(mmsModel: any): void {
    this.mmsModel = mmsModel;
    this.hasMmsModel = true;
  }

  private getDriverNameFromDriverMetadata(driverMetadata: IPdModelCategoryDriver): string {
    const parseIndex = driverMetadata.label.indexOf(':');
    const isPaymentModel = this.mmsModel.metadata.modelId.toLowerCase().includes(ModelId.PAYMENT);
    if (isPaymentModel) {
      return driverMetadata.cddName;
    }
    const driverName = parseIndex > 0 ? driverMetadata.label.substring(parseIndex + 2) : driverMetadata.label;
    return driverName;
  }

  private createDriversGrids(driversContributionPdDrivers: IPdDriversChartData): ITabbedGrid[] {
    const ratios = [...driversContributionPdDrivers.ratios].reverse();
    const contributions = [...driversContributionPdDrivers.contributions].reverse();
    const percentiles = [...driversContributionPdDrivers.percentiles].reverse();

    const driversMetadata: IPdModelCategoryDriver[] = this.collectDriversMetadata();
    const driverRowTypeDetails = driversMetadata.map(driverMetadata => {
      const driverName = this.getDriverNameFromDriverMetadata(driverMetadata);
      return {
        name: driverName,
        label: driverMetadata.label,
        valueType: driverMetadata.valueType,
        currency: driverMetadata.currency
      };
    });

    const driverRatiosRowData: IPdDriverDetails[] = this.constructRowDetailsFromDrivers(driverRowTypeDetails, DriverTypes.RATIO);
    const driverContributionRowData: IPdDriverDetails[] = this.constructRowDetailsFromDrivers(
      driverRowTypeDetails,
      DriverTypes.CONTRIBUTION
    );
    const driverPercentileRowData: IPdDriverDetails[] = this.constructRowDetailsFromDrivers(driverRowTypeDetails, DriverTypes.PERCENTILE);

    const ratiosColumns: ColDef[] = [];
    const contributionsColumns: ColDef[] = [];
    const percentilesColumns: ColDef[] = [];

    ratiosColumns.push(this.generateNameColumn());
    contributionsColumns.push(this.generateNameColumn());
    percentilesColumns.push(this.generateNameColumn());

    const ratiosGridData: IPdDriverInput[] = [];
    const contributionsGridData: IPdDriverInput[] = [];
    const percentilesGridData: IPdDriverInput[] = [];

    ratios.forEach(ratio => ratiosGridData.push(this.constructGridDataFromDrivers(ratio.drivers)));
    contributions.forEach(contribution => contributionsGridData.push(this.constructGridDataFromDrivers(contribution.drivers)));
    if (percentiles && percentiles.length > 0) {
      percentiles.forEach(percentile => percentilesGridData.push(this.constructGridDataFromDrivers(percentile.drivers)));
    }
    ratios.forEach((ratio, index: number) => {
      ratiosColumns.push(this.generateDataColumn(ratio.date, ratiosGridData[index]));
    });
    contributions.forEach((contribution, index: number) =>
      contributionsColumns.push(this.generateDataColumn(contribution.date, contributionsGridData[index]))
    );
    if (percentiles && percentiles.length > 0) {
      percentiles.forEach((percentile, index: number) =>
        percentilesColumns.push(this.generateDataColumn(percentile.date, percentilesGridData[index]))
      );
    }

    let ratiosGridOptions: GridOptions = {};
    let contributionsGridOptions: GridOptions = {};
    let percentilesGridOptions: GridOptions = {};

    ratiosGridOptions = this.getGridOptions(driverRatiosRowData, ratiosColumns);
    ratiosGridOptions.onGridReady = (event: GridReadyEvent) => {};
    ratiosGridOptions.onGridSizeChanged = (event: GridReadyEvent) => event.api.sizeColumnsToFit();

    contributionsGridOptions = this.getGridOptions(driverContributionRowData, contributionsColumns);
    contributionsGridOptions.onGridReady = (event: GridReadyEvent) => {};
    contributionsGridOptions.onGridSizeChanged = (event: GridReadyEvent) => event.api.sizeColumnsToFit();

    percentilesGridOptions = this.getGridOptions(driverPercentileRowData, percentilesColumns);
    percentilesGridOptions.onGridReady = (event: GridReadyEvent) => {};
    percentilesGridOptions.onGridSizeChanged = (event: GridReadyEvent) => event.api.sizeColumnsToFit();

    this.gridOptions = [ratiosGridOptions, contributionsGridOptions, percentilesGridOptions];

    const tabbedGrids: ITabbedGrid[] = [];
    this.tabNames.forEach((name, index: number) => {
      tabbedGrids.push({ tabName: name, grid: this.gridOptions[index] });
    });
    ratiosGridOptions.onGridReady = (event: GridReadyEvent) => {
      event.api.sizeColumnsToFit();
    };

    return tabbedGrids;
  }

  private collectDriversMetadata() {
    const driversMetada: IPdModelCategoryDriver[] = [];
    this.mmsModel.metadata.pdModelDrivers.categories.map(category => {
      category.drivers.map(driver => driversMetada.push(driver));
    });
    return driversMetada;
  }

  private constructRowDetailsFromDrivers(drivers: IPdDriver[], type: string): IPdDriverDetails[] {
    const pdDriverDetails: IPdDriverDetails[] = [];
    drivers.forEach(driver => {
      pdDriverDetails.push({
        key: driver.name,
        label: driver.label,
        type,
        valueType: driver.valueType,
        currency: driver.currency
      });
    });
    return pdDriverDetails;
  }

  private constructGridDataFromDrivers(drivers: IPdDriver[]): IPdDriverInput {
    const pdDriverInput: IPdDriverInput = {};
    drivers.forEach(driver => {
      pdDriverInput[driver.name] = Utils.isNullOrUndefined(driver.value) ? '-' : driver.value;
    });
    return pdDriverInput;
  }

  private generatedCellStyles(heavyFontStyle: boolean, rightAlignContent: boolean) {
    const defaultCellStyleList = ['edfx-ag-grid-standard-cell', 'edfx-ag-grid-no-side-borders-cell'];
    if (rightAlignContent) {
      defaultCellStyleList.push('ag-right-aligned-cell');
    }
    if (heavyFontStyle) {
      defaultCellStyleList.push('edfx-ag-grid-cell-heavy-font');
    }
    return defaultCellStyleList;
  }

  private formatValueWithSymbol(value: number, key: string, type: string, valueType: string, currency: string) {
    if (Utils.isNullOrUndefined(value) || isNaN(value)) {
      return '-';
    }
    if (DriverTypes.RATIO === type) {
      const ratioText = DriversUtils.formatRatioText(value, key, valueType, currency);
      return `${ratioText}`;
    } else if (DriverTypes.CONTRIBUTION === type) {
      return `${value.toFixed(2)}%`;
    } else if (DriverTypes.PERCENTILE === type) {
      const valueOrdinal = this.ordinalFormatterPipe.ordinalFormatter(value.toFixed(0));
      return `${valueOrdinal}`;
    }
    return '-';
  }

  public getGridOptions(rowData: IPdDriverDetails[], colDef: ColDef[]): GridOptions {
    return {
      suppressCellFocus: true,
      pagination: false,
      tooltipShowDelay: 1000,
      rowData,
      rowHeight: 48,
      headerHeight: 48,
      animateRows: true,
      suppressColumnVirtualisation: true,
      rowBuffer: rowData.length,
      suppressRowHoverHighlight: true,
      components: {},
      colResizeDefault: 'shift',
      defaultColDef: {
        getQuickFilterText: (params: any) => {
          if (!params.column.isVisible()) {
            return null;
          } else {
            return params.value;
          }
        },
        filter: false,
        sortable: true,
        suppressSizeToFit: false,
        suppressMovable: true,
        resizable: true,
        headerCheckboxSelection: false
      },
      columnDefs: colDef
    };
  }

  getCompanyModelName() {
    return this.companyModel?.modelName || DEFAULT_TEXT_NA;
  }

  private generateNameColumn() {
    const $this = this;
    return {
      cellClass: (params: any) => this.generatedCellStyles(false, false),
      valueGetter: (params: any) => ($this.isFinancialModel ? params.data.key : params.data.label),
      pinned: true,
      minWidth: 400,
      width: 400
    };
  }

  private generateDataColumn(driverDate: string, driverData: IPdDriverInput) {
    const $this = this;
    const minWidth = 200;
    return {
      cellClass: (params: any) => this.generatedCellStyles(false, true),
      headerComponent: GridDatePickerHeaderComponent,
      headerComponentParams: (params: ICellRendererParams) => ({
        name: MomentService.formatDateToMMMYYYY(driverDate),
        isEditableHeader: false,
        rightAlignHeaderLabel: true,
        heavyFontStyle: true
      }),
      valueGetter: (params: any) =>
        $this.formatValueWithSymbol(
          +$this.getDriverValueByKey(driverData, params.data.key),
          params.data.key,
          params.data.type,
          params.data.valueType,
          params.data.currency
        ),
      minWidth
    };
  }

  private getDriverValueByKey(driverData: IPdDriverInput, key: string): string | number {
    let driverDataKey = '';
    for (const driverKey in driverData) {
      if (driverKey.toLowerCase() === key.toLowerCase()) {
        driverDataKey = driverKey;
        break;
      }
    }
    return driverData[driverDataKey];
  }

  private hasPdDriversData(driversContributionPdDrivers: IPdDriversChartData): boolean {
    if (!driversContributionPdDrivers) {
      return false;
    }
    const hasContributions = driversContributionPdDrivers.contributions && driversContributionPdDrivers.contributions.length > 0;
    const hasPercentiles = driversContributionPdDrivers.percentiles && driversContributionPdDrivers.percentiles.length > 0;
    const hasRatios = driversContributionPdDrivers.ratios && driversContributionPdDrivers.ratios.length > 0;

    return hasContributions || hasPercentiles || hasRatios;
  }
}
