export interface ICompanyModel {
  id?: string;
  dataType?: string;
  dataSource?: string;
  confidenceIndicator?: string;
  confidenceDescription?: string;
  modelName?: string;
  modelDocumentationLink?: string;
  latestKnownPdDate?: string;
  latestKnownPdValue?: number;
  latestKnownQuantitativePd?: number;
  modelId?: string;
  legalForm: string;
  legalStatus: string;
  legalStatusDate: string;
  impliedRating?: string;
  impliedRatingWithQualitativeOverlay?: string;
  quantitativeImpliedRating?: string;
  pdWithQualitativeOverlay?: number;
}

export enum DriverTypes {
  RATIO = 'ratio',
  CONTRIBUTION = 'contribution',
  PERCENTILE = 'percentile'
}

export interface IPdDriverDetails {
  key: string;
  label?: string;
  type: string;
  valueType: string;
  currency: string;
}

export interface IPdDriverInput {
  [key: string]: string | number;
}

export interface IPdDriverWithColor extends IPdDriver {
  color: string;
  grounded?: boolean; // Starts at 0%
}

export interface IHistoricalPdDrivers {
  pds?: IHistoricalPd[];
  ratios?: IHistoricalPdDriver[];
  percentiles?: IHistoricalPdDriver[];
  contributions?: IHistoricalPdDriver[];
  marketLeverages?: IPdDriverDateValuePair[];
  assetVolatilities?: IPdDriverDateValuePair[];
  defaultPoints?: IPdDriverDateValuePair[];
  edfDrivers?: IEdfDrivers[];
  marketValueOfAssets?: IPdDriverDateValuePair[];
  termStructure?: ITermStructure;
}

export interface ITermStructure {
  forward: ITermStructureForward;
  annualized: ITermStructureAnnualized;
  cumulative: ITermStructureCumulative;
}

export interface ITermStructureForward {
  forward1Y: ITermStructureDataWithIR;
  forward2Y: ITermStructureDataWithIR;
  forward3Y: ITermStructureDataWithIR;
  forward4Y: ITermStructureDataWithIR;
  forward5Y: ITermStructureDataWithIR;
  forward6Y: ITermStructureDataWithIR;
  forward7Y: ITermStructureDataWithIR;
  forward8Y: ITermStructureDataWithIR;
  forward9Y: ITermStructureDataWithIR;
  forward10Y: ITermStructureDataWithIR;
}

export interface ITermStructureAnnualized {
  annualized1Y: ITermStructureDataWithIR;
  annualized2Y: ITermStructureDataWithIR;
  annualized3Y: ITermStructureDataWithIR;
  annualized4Y: ITermStructureDataWithIR;
  annualized5Y: ITermStructureDataWithIR;
  annualized6Y: ITermStructureDataWithIR;
  annualized7Y: ITermStructureDataWithIR;
  annualized8Y: ITermStructureDataWithIR;
  annualized9Y: ITermStructureDataWithIR;
  annualized10Y: ITermStructureDataWithIR;
}

export interface ITermStructureCumulative {
  cumulative1Y: ITermStructureDataWithIR;
  cumulative2Y: ITermStructureDataWithIR;
  cumulative3Y: ITermStructureDataWithIR;
  cumulative4Y: ITermStructureDataWithIR;
  cumulative5Y: ITermStructureDataWithIR;
  cumulative6Y: ITermStructureDataWithIR;
  cumulative7Y: ITermStructureDataWithIR;
  cumulative8Y: ITermStructureDataWithIR;
  cumulative9Y: ITermStructureDataWithIR;
  cumulative10Y: ITermStructureDataWithIR;
}

export interface IPdDriverDateValuePair {
  date: string;
  value: number;
}

export enum ModelId {
  BENCHMARK = 'hmark',
  PAYMENT = 'pay10',
  EDF9 = 'edf9',
  OVERLAY = 'overlay'
}

export interface IHistoricalPdDriver {
  date?: string;
  drivers?: IPdDriver[];
}

export interface IPdDriver {
  name?: string;
  label?: string;
  value?: number;
  type?: string;
  valueType?: string;
  currency?: string;
}

export interface IPDHistoricalInputPayload {
  entityId: string;
  startDate: string;
  endDate: string;
  frequency: string;
  fso: boolean;
}

export interface IPdDriversData {
  data?: IHistoricalPdDrivers;
  loading: boolean;
  error: string | null;
  inputs?: IPdDriversInput;
}

export interface IPdDriversInput {
  startDate?: string;
  endDate?: string;
  entityId?: string;
  frequency?: string;
  fso?: boolean;
}

export interface IPdDriversChartData {
  // TODO: Following three are no longer Available on Response: Needs Revisit
  lastKnownPdDate?: string;
  lastKnownPd?: number; // The pd associated with lastKnownPdDate
  quantitativePd?: number;
  qualitativeOverlayPd?: number; // This pd is available when scorecard and support are applied
  pgs?: boolean;
  pds?: IHistoricalPd[];
  ratios?: IHistoricalPdDriver[];
  percentiles?: IHistoricalPdDriver[];
  contributions?: IHistoricalPdDriver[];
  marketLeverages?: IChartPointNumberDate[];
  marketValueOfAssets?: IChartPointNumberDate[];
  assetVolatilities?: IChartPointNumberDate[];
  defaultPoints?: IChartPointNumberDate[];
  companyPds?: IChartPointNumberDate[];
  startDate: string;
  endDate: string;
  fso: boolean;
  frequency: string;
  entityId: string;
  edfDrivers?: IEdfDrivers[];
  termStructure?: ITermStructureData;
}

export interface ITermStructureDataWithIR {
  pd: number;
  impliedRating: string;
}

export interface ITermStructureData {
  forward: ITermStructureDataWithIR[];
  annualized: ITermStructureDataWithIR[];
  cumulative: ITermStructureDataWithIR[];
}

export interface IEdfDrivers {
  asOfDate: string;
  assetVolatility: string;
  marketLeverage: string;
  marketValueOfAssets: string;
  sharePrice: string;
  sharesOutstanding: string;
  marketCapitalization: string;
  defaultPoint: string;
  shortTermLiabilities: string;
  longTermLiabilities: string;
  adjTotalLiabilities: string;
  currency: string;
}

export interface IHistoricalPd {
  date?: string;
  pd?: number;
  impliedRating?: string;
}

export interface IChartPointNumberDate {
  value: number;
  date: string;
}

export interface IEntity {
  id?: string;
  overlayProcessId?: string;
  parentGroupSupportProcessId?: string;
  parentGroupSupportApplyPd?: boolean;
  name?: string;
  dataType?: IDataType;
  modelId?: string;
  modelName?: string;
  dataSource?: string;
  entityType?: string;
  financialsType?: string;
  confidenceIndicator?: string;
  countryCode?: string;
  stateCode?: string;
  industryCode?: string;
  financialData?: IFinancialData;
  companyDetails?: ICompanyDetails;
  modelDocumentationLinkName?: string;
  modelDocumentationLink?: string;
}

export interface ICompanyEntityData {
  customData: {
    country: string;
    state: string;
    industry: string;
    peerGroupId: string;
  };
  peerGroupName?: string;
  peerId?: string;
}

export interface ICompanyDetails {
  address?: string;
  bvdId?: string;
  customId?: string;
  cusip?: string;
  description?: string;
  domesticParentIdentifier?: string;
  domesticParentName?: string;
  entityContactAddressLine1?: string;
  entityContactAddressLine2?: string;
  entityContactAddressLine3?: string;
  entityContactAddressLine4?: string;
  entityData?: ICompanyEntityData;
  entityWebsite?: string;
  founded?: string;
  globalParentIdentifier?: string;
  globalParentName?: string;
  headquarters?: string;
  headquartersIdentifier?: string;
  headquartersName?: string;
  industry?: string;
  isin?: string;
  contactCity?: string;
  stateCode?: string;
  countryCode?: string;
  isPublic?: boolean;
  isCustom?: boolean;
  legalStatus?: string;
  naicsCode?: string;
  naicsDescription: string;
  nationalId?: INationalIdentifier[];
  ndySector?: string;
  other?: string;
  pid?: string;
  sicCode?: string;
  ticker?: string;
  peerId?: string;
  peerGroupName?: string;
  isBankFlag?: string;
}

export interface INationalIdentifier {
  idName: string;
  idValue: string;
}

export interface IFinancialData {
  financialAge?: 'R' | 'O' | 'S';
  financialDate?: string;
}

export enum IDataType {
  BANKRUPT = 'bankrupt',
  PUBLIC = 'Public',
  PRIVATE = 'Private'
}

export interface ICompanyProfile extends IEntity {
  processId?: string;
  confidenceDescription?: string;
  peerId: string;
  peerGroupName: string;
  updatedDate?: string;
  processStatus?: string;
  processStatusDate?: string;
  naceCode?: string;
  naceDescription?: string;
}

export enum DriverName {
  YEARS_IN_BUSINESS = 'yearsinbusiness'
}

export enum DriverValueTypes {
  RATIO = 'ratio',
  VALUE = 'value'
}

export interface IEntitySearchResponse {
  entities: IEntitySearch[];
}
export interface IEntitySearch {
  contactAddressLine?: string;
  contactCity?: string;
  contactCountryCode?: string;
  contactPostalCode?: string;
  contactStateProvince?: string;
  customEntityIdentifier?: string;
  entityId?: string;
  id?: string;
  displayId?: string;
  identifierBvd?: string;
  identifierOrbis?: string;
  internationalName?: string;
  primaryIndustryNACE?: string;
  primaryIndustryNAICS?: string;
  primaryIndustryNDY?: string;
  primaryIndustryNDYDescription?: string;
  primaryIndustrySIC?: string;
  size?: string;
  pid?: string;
  ticker?: string;
  type?: string;
  globalParentIdentifier?: string;
  globalParentName?: string;
  domesticParentIdentifier?: string;
  domesticParentName?: string;
  headquartersIdentifier?: string;
  headquartersName?: string;
}

export interface IEntitySearchPayload {
  limit: number;
  offset: number;
  query: string;
}
