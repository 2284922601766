import { AfterViewInit, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';

@Component({
  selector: 'edfx-grid-datepicker-header',
  templateUrl: './grid-date-picker-header.component.html',
  styleUrls: ['./grid-date-picker-header.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class GridDatePickerHeaderComponent implements IHeaderAngularComp, AfterViewInit {
  @Output() public updateEmitter: EventEmitter<string> = new EventEmitter<string>();

  public formControl: FormControl;
  public name: string;
  public index: number;
  public isEditableHeader: boolean;
  public rightAlignHeaderLabel: boolean;
  public heavyFontStyle: boolean;
  private params: IHeaderParams;

  constructor() {}

  agInit(params: IHeaderParams): void {
    this.params = params;
    this.formControl = (this.params as any).formControl;
    this.name = (this.params as any).name;
    this.index = (this.params as any).index;
    if (this.formControl) {
      if (!this.formControl.value) {
        this.formControl.setValue(new Date(this.name));
      } else {
        this.emitUpdate(this.formControl.value);
      }
    }
    this.isEditableHeader = (this.params as any).isEditableHeader;
    this.rightAlignHeaderLabel = (this.params as any).rightAlignHeaderLabel;
    this.heavyFontStyle = (this.params as any).heavyFontStyle;
  }

  refresh(params: any): boolean {
    return false;
  }

  isEdit(): boolean {
    return (this.params as any).isEdit ? (this.params as any).isEdit() : false;
  }

  emitIndexToDelete(index: number) {
    return (this.params as any).deleteIndex ? (this.params as any).deleteIndex(index) : -1;
  }
  emitUpdate(value: string) {
    if (this.isEdit() && this.isEditableHeader) {
      (this.params as any).updated(value);
    }
  }

  ngAfterViewInit(): void {}
}
