<div class="d-flex flex-row">
  <div class="full-width card card-container ms-4 me-4">
    <div
      class="card-body"
      *ngIf="!isLoading && !requestInProgress && !tokenInvalid && !historicalDriversUnavailable && hasEntitlement && !invalidEntityId"
    >
      <div class="card-title">{{title}}</div>
      <div
        *ngIf="!hasTabbedGridData() && !displayInactiveMessage"
        class="d-flex flex-row align-items-center justify-content-center full-height full-width"
      >
        <span class="override-message"> {{'GLOBAL.DATA.NO_DATA_TO_DISPLAY' | transloco}} </span>
      </div>
      <div *ngIf="!displayInactiveMessage && hasTabbedGridData()">
        <div *ngIf="!showVerticalTabs">
          <mat-tab-group class="tab-group-size">
            <mat-tab *ngFor="let tabbedGrid of tabbedGrids; let index = index" label="{{tabbedGrid.tabName}}">
              <div class="grid" fxLayout="column">
                <div class="ag-grid-wrapper card" fxLayout="column" fxFlex>
                  <ag-grid-angular class="ag-theme-material" domLayout="autoHeight" fxFlex [gridOptions]="tabbedGrid.grid">
                  </ag-grid-angular>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div *ngIf="displayInactiveMessage" class="d-flex flex-row align-items-center justify-content-center full-height full-width">
        <span class="override-message"> {{inactiveMessage}} </span>
      </div>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-around center">
  <div class="spinner" *ngIf="isLoading && hasEntitlement"></div>
</div>
<p class="token-invalid-message" *ngIf="tokenInvalid && !isLoading && !requestInProgress">{{ "TOKEN.INVALID_MESSAGE" | transloco }}</p>
<p class="financial-driver-unavailable" *ngIf="!tokenInvalid && !isLoading && historicalDriversUnavailable">
  {{ "COMPANY.DRIVERS.HISTORICAL_DRIVER_CONTRIBUTION_GRID.UNAVAILABLE" | transloco }}
</p>
<ng-container *ngIf="!hasEntitlement && !requestInProgress">
  <p class="token-invalid-message">User does not have EDF-X access. Please contact your Moody's representative.</p>
</ng-container>
<ng-container *ngIf="invalidEntityId && !isLoading">
  <p class="token-invalid-message">Entity id {{entityId}} does not exists.</p>
</ng-container>
