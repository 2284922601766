import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridDatePickerHeaderComponent } from './grid-date-picker-header.component';

@NgModule({
  declarations: [GridDatePickerHeaderComponent],
  exports: [GridDatePickerHeaderComponent],
  imports: [CommonModule],
  schemas: []
})
export class GridDatePickerHeaderModule { }
