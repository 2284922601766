import { HttpClientModule } from '@angular/common/http';
import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HistoricalDriverContributionComponent } from './components/views/edfx-historical-driver-contribution/historical-driver-contribution.component';
import { HistoricalDriverContributionModule } from './components/views/edfx-historical-driver-contribution/historical-driver-contribution.module';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, HistoricalDriverContributionModule, HttpClientModule],
  providers: [],
  bootstrap: []
})
export class AppModule implements DoBootstrap {
  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    const historicalDriverContributionElement = createCustomElement(HistoricalDriverContributionComponent, { injector: this.injector });
    customElements.define('edfx-historical-driver-contribution', historicalDriverContributionElement);
  }
}
