import * as moment from 'moment';

export class MomentService {
  public static formatDateToDDMMMYYYY(date: string) {
    return moment(date).format('DD MMM YYYY');
  }

  public static formatDateToYYYYDDMMM(date: string) {
    return moment(date).format('YYYY MMM DD');
  }

  public static formatDateToMMDDYYYY(date: string) {
    return moment(date).format('MM/DD/YYYY');
  }

  public static formatDateToYYYYMMDD(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  public static formatDateToMMMYYYY(date: string) {
    return moment(date).format('MMM YYYY');
  }
}
